import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc,
  addDoc,
} from "@firebase/firestore";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBtn,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
} from "mdb-react-ui-kit";
import { firestore } from "../firebase/firebaseSetup";
import { Chip } from "@material-tailwind/react";
export default function Withdraw() {
  var time = new Date();
  const [type, setType] = useState("");
  const [number, setNumber] = useState("");
  const [click, setClick] = useState(false);
  const [amount, setAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [point, setPoint] = useState(0);
  const handleIncrement = (e) => {
    e.preventDefault();
    if (amount < point) {
      setAmount(amount + 1000);
      setErrorMessage("");
    } else {
      setErrorMessage(
        "Maximum limit " + point + " point/ " + point / 100 + " tk"
      );
    }
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (amount > 0) {
      setAmount(amount - 1000);
      setErrorMessage("");
    } else {
      setErrorMessage("Invalid point");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount <= 0) {
      setErrorMessage("Invalid amount");
    } else if (type === "") {
      setErrorMessage("Invalid withdraw type");
    } else {
      setClick(true);
      const historyRef = await collection(firestore, "withdraw");
      const receiverRef = await query(
        collection(firestore, "users"),
        where("mobile", "==", localStorage.getItem("mobile"))
      );
      const receiver = await getDocs(receiverRef);
      await receiver.forEach(async (user) => {
        let history = {
          date:
            time.getDate() +
            "/" +
            (time.getMonth() + 1).toString() +
            "/" +
            time.getFullYear(),
          time:
            (time.getHours() > 12
              ? time.getHours() - 12
              : time.getHours() === 0
              ? time.getHours() + 12
              : time.getHours()) +
            ":" +
            time.getMinutes() +
            (time.getHours() > 12 ? " PM" : " AM"),
          amount,
          type,
          mobile: number,
          status: "pending",
        };
        const getUser = await doc(firestore, "users", user.id);
        await updateDoc(getUser, {
          point: parseInt(user.data()["point"]) - amount,
          withdrawHistory: [history, ...user.data()["withdrawHistory"]],
        });
        await addDoc(historyRef, history);
        window.location.replace("/");
      });
    }
  };
  const getData = async () => {
    setLoading(true);
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      setPoint(user.data()["point"]);
    });
    setLoading(false);
  };

  useEffect(() => {
    localStorage.getItem("email") && getData();
  }, []);
  return (
    <MDBCard className="mt-2 pb-2">
      <MDBCardTitle>Withdraw</MDBCardTitle>
      <MDBCardBody>
        {loading ? (
          <MDBSpinner className="mx-2" color="warning">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        ) : localStorage.getItem("email") ? (
          <div className="d-flex justify-content-center mb-3">
            <MDBCard background="warning">
              <MDBCardBody className="d-flex">
                <div className="d-flex">
                  <Chip
                    size="sm"
                    style={{ backgroundColor: "white" }}
                    color="amber"
                    className="mr-2"
                    value={
                      "Total amount: " +
                      point.toLocaleString("en-IN") +
                      " point/ " +
                      (point / 100).toLocaleString("en-IN") +
                      " taka"
                    }
                  />
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        ) : (
          <p>Please login</p>
        )}

        {errorMessage && (
          <div
            className="d-flex justify-content-center"
            style={{ color: "red" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>
            <span>{errorMessage}</span>
          </div>
        )}
        <MDBDropdown>
          <MDBDropdownToggle>
            {type ? type : "Select account type"}
          </MDBDropdownToggle>
          <MDBDropdownMenu dark>
            <MDBDropdownItem link onClick={(e) => setType("Bkash (personal)")}>
              Bkash (personal)
            </MDBDropdownItem>
            {/* <MDBDropdownItem link onClick={() => setType("Nagad")}>Nagad</MDBDropdownItem>
          <MDBDropdownItem link onClick={() => setType("Rocket")}>Rocket</MDBDropdownItem> */}
          </MDBDropdownMenu>
        </MDBDropdown>
        <form onSubmit={handleSubmit}>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <MDBInput
              type="number"
              id="username"
              required
              label="Account number"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="mt-3 d-flex">
            <MDBBtn disabled={amount <= 0} onClick={handleDecrement}>
              -
            </MDBBtn>
            <MDBInput
              type="text"
              label={amount + " point/" + amount / 100 + " taka"}
              disabled
              readOnly
            />
            <MDBBtn onClick={handleIncrement}>+</MDBBtn>
          </div>

          <MDBBtn
            disabled={click}
            className="mt-3"
            type="submit"
            color="warning"
          >
            Withdraw
          </MDBBtn>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
}
