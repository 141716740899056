import React, { useState } from 'react';
import QRReader from '@wypratama/react-qr';
import '@wypratama/react-qr/dist/style.css';
import { useNavigate } from 'react-router-dom';

export const QR = () => {
  const [scanned, setScanned] = useState(false);
  let navigate = useNavigate();
  const handleResult = result => {
    if (
      new RegExp(
        '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
      ).test(result)
    ) {
      setScanned(true);
      window.location.replace(result);
    } else if (new RegExp('^\\d{11}$').test(result)) {
      navigator.clipboard.writeText(result);
      navigate('/send');
    } else if (new RegExp('^[a-zA-Z0-9]{10}$').test(result)) {
      navigator.clipboard.writeText(result);
      window.location.replace('/coupon_withdraw');
    }
  };

  return !scanned ? (
    <div
      style={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h1>Scan a QR code</h1>
      <QRReader onResult={handleResult} width="auto" />
    </div>
  ) : (
    <div style={{ height: '100vh' }}>
      <h1>QR Code scanned successfully</h1>
    </div>
  );
};
