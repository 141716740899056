import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  updateDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";
import { Link } from "react-router-dom";

export default function CouponWithdraw() {
  var time = new Date();
  const [errorMessage, setErrorMessage] = useState("");
  const [coupon, setCoupon] = useState("");
  const [point, setPoint] = useState(0);
  const [click, setClick] = useState(true);
  const submit = async (e) => {
    e.preventDefault();
    setClick(true);
    const couponDoc = await getDocs(
      query(collection(firestore, "coupon"), where("coupon", "==", coupon))
    );

    couponDoc.forEach((element) => {
      const getCoupon = doc(firestore, "coupon", element.id);
      updateDoc(getCoupon, {
        userName: localStorage.getItem("username"),
        user: localStorage.getItem("email"),
        used: true,
        redeemDate:
          time.getDate() +
          "/" +
          (time.getMonth() + 1).toString() +
          "/" +
          time.getFullYear(),
        redeemTime:
          (time.getHours() > 12
            ? time.getHours() - 12
            : time.getHours() === 0
            ? time.getHours() + 12
            : time.getHours()) +
          ":" +
          time.getMinutes() +
          (time.getHours() > 12 ? " PM" : " AM"),
      })
        .then(async (e) => {
          const userDoc = await getDocs(
            query(
              collection(firestore, "users"),
              where("email", "==", localStorage.getItem("email"))
            )
          );
          userDoc.forEach(async (element) => {
            const getUser = await doc(firestore, "users", element.id);
            let data = {
              date:
                time.getDate() +
                "/" +
                (time.getMonth() + 1).toString() +
                "/" +
                time.getFullYear(),
              time:
                (time.getHours() > 12
                  ? time.getHours() - 12
                  : time.getHours() === 0
                  ? time.getHours() + 12
                  : time.getHours()) +
                ":" +
                time.getMinutes() +
                (time.getHours() > 12 ? " PM" : " AM"),
              point,
              coupon,
            };
            if (element.data()["redeemHistory"]) {
              await updateDoc(getUser, {
                point: element.data()["point"] + point,
                redeemHistory: [data, ...element.data()["redeemHistory"]],
              });
            } else {
              await updateDoc(getUser, {
                point: element.data()["point"] + point,
                redeemHistory: [data],
              });
            }
          });
        })
        .then((e) => {
          window.location.replace("/");
        });
    });
  };
  const details = async () => {
    if (coupon.length > 0) {
      const couponDoc = await getDocs(
        query(collection(firestore, "coupon"), where("coupon", "==", coupon))
      );
      if (couponDoc.empty) {
        setClick(true);
        setPoint(0);
        setErrorMessage("This coupon is invalid!");
      } else {
        couponDoc.forEach((item) => {
          if (item.data()["used"] === true) {
            setPoint(0);
            setErrorMessage("This coupon in already used");
          } else {
            setErrorMessage("");
            setClick(false);
          }
          setPoint(item.data()["amount"]);
        });
      }
    }
  };
  useEffect(() => {
    window.isSecureContext &&
      navigator.clipboard.readText().then((text) => {
        if (new RegExp("^[a-zA-Z0-9]{10}$").test(text)) {
          setCoupon(text);
        }
      });
    details();
  }, [coupon]);

  return (
    <div className=" justify-content-around" style={{ minHeight: "100vh" }}>
      {errorMessage && <div>{errorMessage}</div>}
      {localStorage.getItem("email") ? (
        <form onSubmit={submit}>
          <div className="m-2">
            <MDBInput
              type="text"
              required
              label={point > 0 ? point.toString() + " point" : "Coupon"}
              value={coupon}
              onChange={(e) => {
                setCoupon(e.target.value);
              }}
            />
          </div>
          <MDBBtn type="submit" disabled={click} color="warning" rounded>
            Redeem
          </MDBBtn>
        </form>
      ) : (
        <div>
          <div
            style={{ height: "78vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <h1>
              Please <Link to="/login">Login</Link> or{" "}
              <Link to="/register">Register</Link>
            </h1>
          </div>
        </div>
      )}
    </div>
  );
}
