import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import RegisterPage from "./components/Register";
import Home from "./components/Home";
import Users from "./components/Users";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Send from "./components/Send";
import Withdraw from "./components/Withdraw";
import { QR } from "./components/QR";
import Admin from "./components/Admin";
import CouponWithdraw from "./components/CouponWithdraw";
import Subscription from "./components/Subscription";
import BottomBar from "./components/Bottombar";
import Ranking from "./components/Ranking";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route path="/register" Component={RegisterPage} />
          <Route path="/ranking" Component={Ranking} />
          <Route path="/users" Component={Users} />
          <Route path="/login" Component={Login} />
          <Route path="/qr" Component={QR} />
          <Route path="/profile" Component={Profile} />
          <Route path="/send" Component={Send} />
          <Route path="/subscription" Component={Subscription} />
          <Route path="/withdraw" Component={Withdraw} />
          <Route path="/admin" Component={Admin} />
          <Route path="/coupon_withdraw" Component={CouponWithdraw} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <BottomBar></BottomBar>
      </Router>
    </div>
  );
}

export default App;
