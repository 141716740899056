import React from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { firestore } from "../firebase/firebaseSetup";
import { collection, getDocs, where, query } from "@firebase/firestore";

export default function Navbar() {
  const getData = async () => {
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      setPoint(user.data()["point"]);
    });
  };

  const [point, setPoint] = useState("");

  useEffect(() => {
    localStorage.getItem("email") && getData();
  }, []);

  return (
    <MDBNavbar
      sticky
      light
      style={{
        maxWidth: "100%",
        border: "2px solid black",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        backgroundColor: "#ffcf3d",
      }}
    >
      <MDBContainer fluid>
        <MDBNavbarBrand
          className=" justify-content-center"
          style={{ width: "100%" }}
        >
          <div className="d-flex flex-column justify-content-center">
            <h1>T-Food BD Points</h1>
          </div>
        </MDBNavbarBrand>
        <MDBNavbarNav className="d-flex flex-row justify-content-evenly">
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="/">
              <MDBNavbarLink>
                <MDBIcon fas icon="home" />
                <p>Home</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="/ranking">
              <MDBNavbarLink>
                <MDBIcon fas icon="users" />
                <p>Ranking</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="/qr">
              <MDBNavbarLink>
                <MDBIcon fas icon="qrcode" />
                <p>QR Code</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag="a" className="nav-link" role="button">
                <MDBIcon far icon="user" />
                <br></br>
                <span>Account</span>
              </MDBDropdownToggle>
              {localStorage.getItem("email") ? (
                <MDBDropdownMenu>
                  <Link to="/profile">
                    <MDBDropdownItem link>Profile</MDBDropdownItem>
                  </Link>
                  {localStorage.getItem("type") === "admin" ? (
                    <Link to="/admin">
                      <MDBDropdownItem link>Coupon</MDBDropdownItem>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {localStorage.getItem("type") === "admin" ? (
                    <Link to="/subscription">
                      <MDBDropdownItem link>Subscription</MDBDropdownItem>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  <MDBDropdownItem
                    onClick={() => {
                      localStorage.clear();
                      window.location.replace("/");
                    }}
                    link
                  >
                    Logout
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              ) : (
                <MDBDropdownMenu>
                  <Link to="/register">
                    <MDBDropdownItem link>Register</MDBDropdownItem>
                  </Link>
                  <Link to="/Login">
                    <MDBDropdownItem link>Login</MDBDropdownItem>
                  </Link>
                </MDBDropdownMenu>
              )}
            </MDBDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
