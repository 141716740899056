import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";
import { MDBCard, MDBListGroup, MDBSpinner } from "mdb-react-ui-kit";
import { Checkbox, Chip } from "@material-tailwind/react";

export default function Users() {
  // const userData = [];
  const [userData, setUserData] = useState([]);
  const [filteredUser, setFilteredUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ranking, setRanking] = useState(true);
  const getMembers = async () => {
    setLoading(true);
    const doc_refs = await getDocs(query(collection(firestore, "users")));
    const doc_refs_filter = await getDocs(
      query(collection(firestore, "users"), orderBy("point", "desc"))
    );
    doc_refs.forEach((user) => {
      // userData.push({
      //   id: user.id,
      //   ...user.data()
      // })
      setUserData((oldUser) => [...oldUser, { id: user.id, ...user.data() }]);
    });
    doc_refs_filter.forEach((user) => {
      // userData.push({
      //   id: user.id,
      //   ...user.data()
      // })
      setFilteredUser((oldUser) => [
        ...oldUser,
        { id: user.id, ...user.data() },
      ]);
    });
    setLoading(false);
  };
  const handleCheck = () => {
    setRanking(!ranking);
  };
  useEffect(() => {
    setUserData([]);
    setFilteredUser([]);
    getMembers();
  }, []);

  return (
    <div className="">
      {loading ? (
        <div style={{ minHeight: "100vh" }}>
          <MDBSpinner className="mx-2" color="warning">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      ) : (
        <div className="d-fle">
          <div className="d-flex justify-content-center m-2">
            {/* <MDBCheckbox name='ranking' onChange={handleCheck} value='' checked={ranking} id='ranking' label='Ranking' /> */}
            <Chip
              value=<span>Ranking</span>
              color="green"
              variant="ghost"
              icon={
                <Checkbox
                  color="amber"
                  ripple={false}
                  checked={ranking}
                  onChange={handleCheck}
                  containerProps={{ className: "p-0" }}
                  className="border-green-900 border-2 checked:bg-green-900 checked:border-green-900 -ml-px before:hidden"
                />
              }
            />
          </div>

          <MDBCard>
            <MDBListGroup flush>
              {ranking
                ? filteredUser.map((user, i) => {
                    return (
                      <div>
                        <Chip
                          color={i < 4 && i > 0 ? "green" : "amber"}
                          style={{ borderColor: "black" }}
                          className="m-1"
                          value=<div className="d-flex align-items-center p-2">
                            <div className="me-4 d-flex">
                              <h3 className="me-2">{i}</h3>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-7 h-7"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </div>
                            <div className="d-flex flex-column align-items-between justify-content-start">
                              <p style={{ textAlign: "left" }}>
                                Username: {user["username"]}
                              </p>
                              <p style={{ textAlign: "left" }} className="m-0">
                                Points:{" "}
                                {Number(user["point"]).toLocaleString("en-IN")}
                              </p>
                            </div>
                          </div>
                        />
                      </div>
                    );
                  })
                : userData.map((user, i) => {
                    return (
                      <div>
                        <Chip
                          color="amber"
                          style={{ borderColor: "black" }}
                          className="m-1"
                          value=<div className="d-flex align-items-center p-2">
                            <div className="me-4 d-flex">
                              <h3 className="me-2">{i + 1}</h3>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-7 h-7"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </div>
                            <div className="d-flex flex-column align-items-between justify-content-start">
                              <p style={{ textAlign: "left" }}>
                                Username: {user["username"]}
                              </p>
                              <p style={{ textAlign: "left" }} className="m-0">
                                Point:{" "}
                                {Number(user["point"]).toLocaleString("en-IN")}
                              </p>
                            </div>
                          </div>
                        />
                      </div>
                    );
                  })}
            </MDBListGroup>
          </MDBCard>
        </div>
      )}
    </div>
  );
}
