import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import { addDoc, collection, getDocs, query, where } from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";
import { useEffect } from "react";
export default function Admin() {
  var time = new Date();
  const [coupon, setCoupon] = useState("");
  const [amount, setAmount] = useState(null);
  const [used, setUsed] = useState([]);
  const [unUsed, setUnused] = useState([]);
  const [click, setClick] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getData = async () => {
    const coupon_ref = await getDocs(query(collection(firestore, "coupon")));
    coupon_ref.forEach((element) => {
      if (element.data()["used"] === false) {
        setUnused((oldUnused) => [
          ...oldUnused,
          { id: element.id, ...element.data() },
        ]);
      } else {
        setUsed((oldUsed) => [
          ...oldUsed,
          { id: element.id, ...element.data() },
        ]);
      }
    });
  };

  useEffect(() => {
    setUnused([]);
    getData();
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    setClick(true);
    const couponDoc = await getDocs(
      query(collection(firestore, "coupon"), where("coupon", "==", coupon))
    );
    if (!couponDoc.empty) {
      setErrorMessage("This coupon is already taken!");
    } else if (amount <= 0) {
      setErrorMessage("Invalid amount");
    } else if (new RegExp("^[a-zA-Z0-9]{10}$").test(coupon)) {
      const ref = collection(firestore, "coupon");
      let data = {
        creationDate:
          time.getDate() +
          "/" +
          (time.getMonth() + 1).toString() +
          "/" +
          time.getFullYear(),
        creationTime:
          (time.getHours() > 12
            ? time.getHours() - 12
            : time.getHours() === 0
            ? time.getHours() + 12
            : time.getHours()) +
          ":" +
          time.getMinutes() +
          (time.getHours() > 12 ? " PM" : " AM"),
        redeemDate: "",
        redeemTime: "",
        coupon,
        amount,
        user: localStorage.getItem("email"),
        userName: localStorage.getItem("username"),
        used: false,
      };
      await addDoc(ref, data);
      setCoupon("");
      setAmount(null);
      setErrorMessage("");
    } else {
      setErrorMessage("Invalid coupon");
    }

    setClick(false);
  };
  return (
    <div className=" justify-content-around" style={{ minHeight: "100vh" }}>
      {unUsed.length > 0 && <p>{console.log(unUsed[0]["user"])}</p>}
      {errorMessage && <div>{errorMessage}</div>}
      <form onSubmit={submit}>
        <div className="m-2">
          <MDBInput
            type="text"
            required
            label="Add coupon number"
            value={coupon}
            onChange={(e) => {
              setCoupon(e.target.value);
            }}
          />
        </div>
        <div className="m-2">
          <MDBInput
            type="number"
            required
            label="Point"
            value={amount}
            onChange={(e) => {
              if (e.target.value > 0) {
                setAmount(Number(e.target.value));
              } else {
                setAmount(null);
              }
            }}
          />
        </div>
        <MDBBtn type="submit" disabled={click} color="warning" rounded>
          Submit
        </MDBBtn>
      </form>
      {unUsed.length > 0 && (
        <MDBCard className="m-3">
          <MDBCardTitle>Unused coupons</MDBCardTitle>
          <MDBCardBody>
            <MDBTable>
              <MDBTableHead>
                <tr>
                  <th scope="col">Date/Time</th>
                  <th scope="col">Coupon</th>
                  <th scope="col">Point/Taka</th>
                  <th scope="col">Account</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {unUsed.map((item) => {
                  return (
                    <tr>
                      <th scope="row">
                        {item["creationDate"]}
                        <br />
                        {item["creationTime"]}
                      </th>
                      <td>{item["coupon"]}</td>
                      <td>
                        {item["amount"]} p<br />
                        {item["amount"] / 100} tk
                      </td>
                      <td>
                        {item["user"]}
                        <br />
                        {item["userName"]}
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      )}
      {used.length > 0 && (
        <MDBCard className="m-3">
          <MDBCardTitle>Used coupons</MDBCardTitle>
          <MDBCardBody>
            <MDBTable>
              <MDBTableHead>
                <tr>
                  <th scope="col">Date/Time</th>
                  <th scope="col">Coupon</th>
                  <th scope="col">Point/Taka</th>
                  <th scope="col">Account</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {used.map((item) => {
                  return (
                    <tr>
                      <th scope="row">
                        {item["redeemDate"]}
                        <br />
                        {item["redeemTime"]}
                      </th>
                      <td>{item["coupon"]}</td>
                      <td>
                        {item["amount"]} p<br />
                        {item["amount"] / 100} tk
                      </td>
                      <td>
                        {item["user"]}
                        <br />
                        {item["userName"]}
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      )}
    </div>
  );
}
