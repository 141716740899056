// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBnQpNy-QbRgib13JURp-jPCMjqt-wAsPE',
  authDomain: 'test-app-47224.firebaseapp.com',
  databaseURL: 'https://test-app-47224-default-rtdb.firebaseio.com',
  projectId: 'test-app-47224',
  storageBucket: 'test-app-47224.appspot.com',
  messagingSenderId: '897024846038',
  appId: '1:897024846038:web:f7aed678a9dd0c4dcf8177',
  measurementId: 'G-RXF0EVXJHV',
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);
