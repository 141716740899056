import React, { useState } from 'react';
import { addDoc, collection, getDocs, query, where } from '@firebase/firestore';
import { firestore } from '../firebase/firebaseSetup';
import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
  MDBSpinner,
} from 'mdb-react-ui-kit';
const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const [click, setClicked] = useState(false);
  const [address, setAddress] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [religion, setReligion] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async e => {
    e.preventDefault();
    setErrorMessage('');
    setClicked(true);
    if (password.length < 8) {
      setErrorMessage('Password musth be at least 8 characters');
    } else if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
    } else if (profession === '' || religion === '' || gender === '') {
      setErrorMessage('Fill all the fields');
    } else {
      setErrorMessage('');
      const ref = collection(firestore, 'users');
      const userNameDoc = await getDocs(
        query(collection(firestore, 'users'), where('username', '==', username))
      );
      if (!userNameDoc.empty) {
        setErrorMessage('This username is already taken!');
      } else {
        const emailDoc = await getDocs(
          query(collection(firestore, 'users'), where('email', '==', email))
        );
        if (!emailDoc.empty) {
          setErrorMessage('This email is already taken!');
        } else {
          const mobileDoc = await getDocs(
            query(collection(firestore, 'users'), where('mobile', '==', mobile))
          );
          if (!mobileDoc.empty) {
            setErrorMessage('This mobile is already taken!');
          } else {
            let data = {
              username: fullName,
              fullName,
              email,
              dateOfBirth: dob,
              gender,
              profession,
              type: 'user',
              religion,
              password,
              mobile,
              address,
              sendHistory: [],
              receiveHistory: [],
              withdrawHistory: [],
              redeemHistory: [],
              subscriptionHistory: [],
              point: 0,
            };

            await addDoc(ref, data);
            window.location.replace('/login');
          }
        }
      }
    }
    setClicked(false);
  };

  return (
    <div className="p-4" style={{ minHeight: '100vh' }}>
      <h1>Register Page</h1>
      <form onSubmit={handleRegister}>
        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              type="text"
              required
              label="Full Name"
              id="fullName"
              value={fullName}
              onChange={e => {
                setFullName(e.target.value);
                setUsername(e.target.value);
              }}
            />
          </div>
        </div>

        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              required
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Mobile number"
              type="text"
              required
              id="mobile"
              value={mobile}
              onChange={e => setMobile(e.target.value.trim())}
            />
          </div>
        </div>
        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Address"
              type="text"
              required
              id="email"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </div>
        </div>
        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Password"
              type="password"
              required
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Confirm password"
              type="password"
              required
              id="confirm-password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <div class="d-flex justify-content-around m-2">
          <div>
            <MDBInput
              label="Date of birth"
              type="text"
              required
              id="dob"
              value={dob}
              onChange={e => setDob(e.target.value)}
            />
          </div>
        </div>

        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <MDBDropdown className="m-2">
          <MDBDropdownToggle>
            {profession ? profession : 'Select Profession'}
          </MDBDropdownToggle>
          <MDBDropdownMenu dark>
            <MDBDropdownItem link onClick={() => setProfession('Student')}>
              Student
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setProfession('Employment')}>
              Employment
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setProfession('Unemployment')}>
              Unemployment
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setProfession('Businessman')}>
              Businessman
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setProfession('Housewife')}>
              Housefwife
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setProfession('Others')}>
              Others
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBDropdown className="m-2">
          <MDBDropdownToggle>
            {religion ? religion : 'Select religion'}
          </MDBDropdownToggle>
          <MDBDropdownMenu dark>
            <MDBDropdownItem link onClick={() => setReligion('Muslim')}>
              Muslim
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setReligion('Non-muslim')}>
              Non-muslim
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown className="m-2">
          <MDBDropdownToggle>
            {gender ? gender : 'Select Gender'}
          </MDBDropdownToggle>
          <MDBDropdownMenu dark>
            <MDBDropdownItem link onClick={() => setGender('Male')}>
              Male
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setGender('Female')}>
              Female
            </MDBDropdownItem>
            <MDBDropdownItem link onClick={() => setGender('Others')}>
              Others
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBBtn
          disabled={click}
          type="submit"
          rounded
          color="warning"
          variant="filled"
        >
          {click ? (
            <MDBSpinner size="sm" className="mx-2" color="primary">
              <span className="visually-hidden">Loading...</span>
            </MDBSpinner>
          ) : (
            'Register'
          )}
        </MDBBtn>
      </form>
    </div>
  );
};

export default RegisterPage;
