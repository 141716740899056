import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from '@firebase/firestore';
import { firestore } from '../firebase/firebaseSetup';

export default function Profile() {
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [click, setClick] = useState(false);

  useEffect(() => {
    localStorage.getItem('mobile') && getData();
  }, []);
  const getData = async () => {
    // setLoading(true);
    const doc_refs = await getDocs(
      query(
        collection(firestore, 'users'),
        where('email', '==', localStorage.getItem('email'))
      )
    );
    doc_refs.forEach(user => {
      // setPoint(user.data()['point']);
      setMobile(user.data()['mobile']);

      setUsername(user.data()['username']);
      setEmail(user.data()['email']);

      // console.log(user.data()['sendHistory'])

      // setWithdrawHistory(...withdrawHistory, user.data()["withdrawHistory"]);
      // senHistory = user.data()['sendHistory']
    });
    // setLoading(false);
  };
  const submit = async e => {
    e.preventDefault();
    const userRef = await getDocs(
      query(
        collection(firestore, 'users'),
        where('email', '==', localStorage.getItem('email'))
      )
    );
    userRef.forEach(async user => {
      const getUser = doc(firestore, 'users', user.id);
      await updateDoc(getUser, {
        mobile: mobile,
        username,
        fullName: username,
        email,
      })
        .then(e => {
          localStorage.setItem('email', email);
          localStorage.setItem('username', username);
          localStorage.setItem('fullName', username);
          localStorage.setItem('mobile', mobile);
        })
        .then(e => {
          window.location.replace('/');
        });
    });
  };
  const changePass = async e => {
    e.preventDefault();
    if (password === confirmPassword) {
      const userRef = await getDocs(
        query(
          collection(firestore, 'users'),
          where('email', '==', localStorage.getItem('email'))
        )
      );
      userRef.forEach(async user => {
        const getUser = doc(firestore, 'users', user.id);
        await updateDoc(getUser, {
          password,
        }).then(e => {
          window.location.replace('/');
        });
      });
    } else {
      setErrorMessage("Password doesn't match");
    }
  };
  return (
    <div style={{ minHeight: '100vh' }}>
      <form onSubmit={submit}>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="text"
            id="mobile"
            required
            label="Mobile"
            value={mobile}
            onChange={e => {
              setMobile(e.target.value.trim());
              setErrorMessage('');
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="text"
            id="username"
            required
            label="Username"
            value={username}
            onChange={e => {
              setUsername(e.target.value.trim());
              setErrorMessage('');
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="text"
            id="email"
            required
            label="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value.trim());
              setErrorMessage('');
            }}
          />
        </div>

        <MDBBtn disabled={click} className="mt-3" type="submit" color="warning">
          Update profile
        </MDBBtn>
      </form>
      <h4 style={{ color: 'red' }}>{errorMessage}</h4>
      <form onSubmit={changePass}>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="text"
            id="password"
            required
            label="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value.trim());
              setErrorMessage('');
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="text"
            id="conpassword"
            required
            label="Confirm Password"
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value.trim());
              setErrorMessage('');
            }}
          />
        </div>
        <MDBBtn disabled={click} className="mt-3" type="submit" color="warning">
          Change password
        </MDBBtn>
      </form>
    </div>
  );
}
