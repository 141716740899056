import {
  collection,
  getDocs,
  query,
  addDoc,
  where,
  doc,
  updateDoc,
} from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

export default function Subscription() {
  var time = new Date();
  const [mobile, setMobile] = useState("");
  const [amount, setAmount] = useState(null);
  const [type, setType] = useState("T_Food_BD");
  const [version, setVersion] = useState("version 1");
  const [history, setHistory] = useState([]);
  const [click, setClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (price <= 0) {
      setErrorMessage("Invalid point");
    } else if (localStorage.getItem("mobile") === mobile) {
      setErrorMessage("Can't send to own account");
    } else {
      setClick(true);
      const receiverRef = query(
        collection(firestore, "users"),
        where("mobile", "==", mobile)
      );
      const adminRef = query(
        collection(firestore, "users"),
        where("mobile", "==", "01747999509")
      );
      const admin = await getDocs(adminRef);
      const receiver = await getDocs(receiverRef);
      if (receiver.size > 0) {
        receiver.forEach(async (user) => {
          let paymentHistory = {
            mobile: mobile,
            date: date,
            price: price,
            type: type,
            version: version,
          };
          const getUser = doc(firestore, "users", user.id);

          await updateDoc(getUser, {
            point: parseInt(user.data()["point"]) - price,
            subscriptionHistory: [
              paymentHistory,
              ...user.data()["subscriptionHistory"],
            ],
          });
          const ref = collection(firestore, "subscriptions");
          // const getAdmin = doc(firestore, "users", )
          addDoc(ref, paymentHistory);
          window.location.replace("/");
        });
      } else {
        setErrorMessage("Invalid number");
        setClick(false);
      }
    }
  };
  const getInfo = async () => {
    const coupon_ref = await getDocs(
      query(collection(firestore, "subscriptions"))
    );
    coupon_ref.forEach((element) => {
      // setHistory(...history, element.data());
      setHistory((prevHistory) => [...prevHistory, element.data()]);
    });
  };
  const getData = async () => {
    setLoading(true);
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      //   setPrice(user.data()["point"]);
    });
    setLoading(false);
  };

  useEffect(() => {
    localStorage.getItem("email") && getData();
    getInfo();
    window.isSecureContext &&
      navigator.clipboard.readText().then((text) => {
        if (new RegExp("^\\d{11}$").test(text)) {
          setMobile(text);
        }
      });
  }, []);
  return (
    <>
      <MDBCard className="mt-2 pb-2">
        <MDBCardBody>
          {errorMessage && (
            <div
              className="d-flex justify-content-center"
              style={{ color: "red" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                />
              </svg>
              <span>{errorMessage}</span>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <MDBInput
                type="number"
                id="username"
                required
                label="Account"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                  setErrorMessage("");
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <MDBInput
                type="text"
                id="type"
                required
                label="Type"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setErrorMessage("");
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <MDBInput
                type="text"
                id="date"
                required
                label="Date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  setErrorMessage("");
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <MDBInput
                type="text"
                id="version"
                required
                label="Version"
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value);
                  setErrorMessage("");
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <MDBInput
                type="number"
                id="username"
                required
                label={price > 0 ? "Taka: " + price / 100 : "Point"}
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                  setErrorMessage("");
                }}
              />
            </div>
            <MDBBtn
              disabled={click}
              className="mt-3"
              type="submit"
              color="warning"
            >
              Subscription Fee
            </MDBBtn>
          </form>
        </MDBCardBody>
      </MDBCard>
      {history.length > 0 && (
        <MDBCard className="m-3">
          <MDBCardTitle>Subscription History</MDBCardTitle>
          <MDBCardBody>
            <MDBTable>
              <MDBTableHead>
                <tr>
                  <th scope="col">Type/Mobile</th>
                  <th scope="col">V</th>
                  <th scope="col">Date</th>
                  <th scope="col">Point/Taka</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {history.map((item) => {
                  return (
                    <tr>
                      <th scope="row">
                        {item["type"]} fee
                        <br />
                        {item["mobile"]}
                      </th>
                      <td>{item["version"]}</td>
                      <td>{item["date"]}</td>
                      <td>
                        {item["price"]} p<br />
                        {item["price"] / 100} tk
                      </td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
}
